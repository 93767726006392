<template>
    <div>
        <v-app-bar class="elevation-0">
            <template v-slot:prepend>
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            </template>
            <v-btn variant="text" to="/" icon>
                <img src="../assets/logo-svg.svg" alt="alt" style="width: 30px; height: 30px;">
            </v-btn>

            <v-app-bar-title>
                {{title}}
            </v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="changeTheme">
                <v-icon>
                    mdi-theme-light-dark
                </v-icon>
            </v-btn>
            <v-btn icon @click="logout">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" temporary>
            <v-divider></v-divider>
            <v-list density="compact" nav>
                <v-list-item v-for="(item, index) in navigation_links" :key="index" :to="item.path">
                    <v-icon class="me-2" :color="item.color">
                        {{ item.icon }}
                    </v-icon>
                    {{ item.title }}
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script setup>
    import {
        ref,
        computed,
        watch
    } from 'vue';
    import {
        auth
    } from '../firebase';
    let drawer = ref(false);
    import {
        useRoute
    } from 'vue-router';
    const title = ref('');
    import {
        useTheme
    } from 'vuetify'

    // watch route change, and get meta data
    const route = useRoute();
    watch(() => route.meta, (meta) => {
        title.value = meta.title;
    });
    const navigation_links = computed(() => {
        const content = [{
                path: '/labels-list',
                title: 'Main Sections',
                icon: 'mdi-label',
                color: 'blue'
            },
            {
                path: '/sections-list',
                title: 'Sections',
                icon: 'mdi-view-dashboard',
                color: 'green'
            },
            // {
            //     path : '/items-list',
            //     title : 'Items',
            // },
            // {
            //     path: '/section_gallery-list',
            //     title: 'Section Gallery',
            // },
            {
                path: '/sliders-list',
                title: 'Sliders',
                icon: 'mdi-view-carousel',
                color: 'orange'
            },
            {
                path: '/display_text-list',
                title: 'Display Text',
                icon: 'mdi-text-box',
                color: 'purple'
            },
            {
                path: '/company_team-list',
                title: 'Team',
                icon: 'mdi-account-group',
                color: 'pink'
            },
            // {
            //     path : '/admins-list',
            //     title : 'Admins',
            // },

        ];
        return content;
    });
    const logout = () => {
        auth.signOut();
        localStorage.removeItem('auth');
        window.location.href = '/login';
    };
    const themeVuetify = useTheme()

    const changeTheme = () => {
        const theme = localStorage.getItem('theme');

        if (theme == 'dark') {
            localStorage.setItem('theme', 'light');
            themeVuetify.global.name.value = 'light';
        } else {
            localStorage.setItem('theme', 'dark');
            themeVuetify.global.name.value = 'dark';
        }
    };
</script>