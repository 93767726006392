
import { createRouter, createWebHistory } from 'vue-router'
import {
    auth
} from '../firebase'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./../views/Home.vue'),
        meta:{
            auth : true,
            title : 'Home'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta:{
            auth : false,
            title : 'Login'
        }
    },
    
        {
            path: '/admins-list',
            name: 'ListAdmins',
            component: () => import('./../views/Admins/List.vue'),
            meta:{
                auth : true,
                title : 'Admins List'
            }
        },
            {
            path: '/admins-list/:id',
            name: 'ViewAdmins',
            component: () => import('./../views/Admins/Edit.vue'),
            meta:{
                auth : true,
                title : 'Edit Admin'
            }
        },
        {
            path: '/display_text-list',
            name: 'ListDisplay_text',
            component: () => import('./../views/Display_text/List.vue'),
            meta:{
                auth : true,
                title : 'Display Texts'
            }
        },
            {
            path: '/display_text-list/:id',
            name: 'ViewDisplay_text',
            component: () => import('./../views/Display_text/Edit.vue'),
            meta:{
                auth : true,
                title : 'Edit Display Text'
            }
        },
        {
            path: '/items-list',
            name: 'ListItems',
            component: () => import('./../views/Items/List.vue'),
            meta:{
                auth : true,
                title : 'Items List'
            }
        },
            {
            path: '/items-list/:id',
            name: 'ViewItems',
            component: () => import('./../views/Items/Edit.vue'),
            meta:{
                auth : true,
                title : 'Edit Item'
            }
        },
        {
            path: '/labels-list',
            name: 'ListLabels',
            component: () => import('./../views/Labels/List.vue'),
            meta:{
                auth : true,
                title : 'Labels List'
            }
        },
            {
            path: '/labels-list/:id',
            name: 'ViewLabels',
            component: () => import('./../views/Labels/Edit.vue'),
            meta:{
                auth : true,
                title : 'Edit Label'
            }
        },
        {
            path: '/add-sections',
            name: 'AddSections',
            component: () => import('./../views/Sections/Add.vue'),
            meta:{
                auth : true,
                title : 'Add New Section'
            }
        },
        {
            path: '/sections-list',
            name: 'ListSections',
            component: () => import('./../views/Sections/List.vue'),
            meta:{
                auth : true,
                title : 'Sections List'
            }
        },
            {
            path: '/sections-list/:id',
            name: 'ViewSections',
            component: () => import('./../views/Sections/Edit.vue'),
            meta:{
                auth : true,
                title : 'Edit Section'
            }
        },
        {
            path: '/section_gallery-list',
            name: 'ListSection_gallery',
            component: () => import('./../views/Section_gallery/List.vue'),
            meta:{
                auth : true,
                title : 'Section Gallery'
            }
        },
            {
            path: '/section_gallery-list/:id',
            name: 'ViewSection_gallery',
            component: () => import('./../views/Section_gallery/Edit.vue'),
            meta:{
                auth : true,
                title : 'Edit Section Gallery'
            }
        },
        {
            path: '/sliders-list',
            name: 'ListSliders',
            component: () => import('./../views/Sliders/List.vue'),
            meta:{
                auth : true,
                title : 'Sliders List'
            }
        },
            {
            path: '/sliders-list/:id',
            name: 'ViewSliders',
            component: () => import('./../views/Sliders/Edit.vue'),
            meta:{
                auth : true,
                title : 'Edit Slider'
            }
        },
        {
            path: '/company_team-list',
            name: 'ListCompany_team',
            component: () => import('./../views/Company_team/List.vue'),
            meta:{
                auth : true,
                title : 'Company Team'
            }
        },
            {
            path: '/company_team-list/:id',
            name: 'ViewCompany_team',
            component: () => import('./../views/Company_team/Edit.vue'),
            meta:{
                auth : true,
                title : 'Edit Company Team'
            }
        },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
  
router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    auth.onAuthStateChanged(user => {
        if (to.matched.some(record => record.meta.auth) && !user) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        } else if(!to.matched.some(record => record.meta.auth) && !user){
            next()
        } else {
            next()
        }
    })
})
export default router
        