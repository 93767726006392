<template>
    <v-app>
        <v-locale-provider ltr>
            <APPNavbar v-if="isAuth"></APPNavbar>
            <v-main class="px-2">
                <router-view v-if="!loading" />
                <v-container style="height: 97vh;" v-if="loading">
                    <v-row
                        justify="center"
                        align="center"
                        style="height: 100%;"
                    >
                        <v-col cols="12" class="text-center">
                            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
        </v-locale-provider>
    </v-app>
</template>
<script setup>
    import {
        ref,
        onMounted
    } from 'vue';
    import {
        useTheme
    } from 'vuetify';

    import APPNavbar from './components/Navbar';
    import {
        auth
    } from './firebase';

    const loading = ref(true);
    const isAuth = ref(false);

    // Use onMounted instead of mounted lifecycle hook
    onMounted(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                isAuth.value = true;
                loading.value = false;
            } else {
                isAuth.value = false;
                loading.value = false;
            }
        });

        // check theme from local storage
        const themeVuetify = useTheme()
        const theme = localStorage.getItem('theme');
        if (theme) {
            themeVuetify.global.name.value = theme;
        } else {
            themeVuetify.global.name.value = 'light';
        }
    });
</script>