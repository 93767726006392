
import { createStore } from 'vuex'
import axios from 'axios'
import languages from './languages.json'
import moment from 'moment'
import {auth} from '../firebase'

export default createStore({
    state: {
        languages: languages,
        language: languages[0],
    },
    getters: {
        language: state => state.language,
        languages: state => state.languages,
    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },
    },
    actions: {
        
    },
})
        